@use "../../../lib/themes/theme.scss" as theme;
.root {
  flex-direction: column;
  padding: 32px 16px;
}
.block {}
.paper.paper {
  padding: 24px;
}

.item {
  display: inline-block;
  width: 50%;
  padding: 16px 0;
}
.accountBlock {
  text-align: right;
  padding-right: 8px;
}
.sendButton {
  display: block;
  margin: 16px 0;
}