@use "../../lib/themes/theme.scss" as theme;

.Select input::-ms-clear {
  display: none !important;
}

.Select input::-ms-reveal {
  display: none !important;
}

.Select.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: darkgray !important;
}

.Select.is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent darkgray !important;
  border-width: 0 5px 5px;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: transparent;
  box-shadow: inset 0 1px 1px transparent, 0 0 0 3px transparent !important;
  background: #fff;
}

.Select.has-value.Select--single
> .Select-control
.Select-value
a.Select-value-label:hover,
.Select.has-value.is-pseudo-focused.Select--single
> .Select-control
.Select-value
a.Select-value-label:hover,
.Select.has-value.Select--single
> .Select-control
.Select-value
a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single
> .Select-control
.Select-value
a.Select-value-label:focus {
  outline: none;
  text-decoration: underline;
}

.Select-control:hover {
  box-shadow: 0 0 0 lightgray !important;
}

.Select-option.is-focused {
  // background-color: rgba(255, 220, 74, 1) !important;
  color: #333;
}

.Select--multi .Select-value {
  background-color: rgb(220, 220, 220) !important;
  /* Fallback color for IE 8 */
  border-radius: 20px;
  /* Fallback color for IE 8 */
  border: 0 solid darkgray !important;
  color: black;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
  padding: 5px;
  padding-bottom: 3px;
}

.Select--multi a.Select-value-label {
  color: darkgray !important;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-radius: 20px;
  border-right: 0 solid darkgray !important;
  /* Fallback color for IE 8 */
  padding: 1px 5px 3px;
  color: black;
}

.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  background-color: yellow !important;
  /* Fallback color for IE 8 */
  background-color: rgb(220, 220, 220) !important;
  color: white;
}

.Select--multi .Select-value-icon:active {
  background-color: white !important;
  /* Fallback color for IE 8 */
  background-color: lightgray !important;
}

.Select--multi.Select--rtl .Select-value-icon {
  cursor: pointer;
  border-radius: 20px;
  border-right: 0 solid darkgray !important;
  /* Fallback color for IE 8 */
  padding: 1px 5px 3px;
  color: black;
}

.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid darkgray !important;
  color: #333;
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid darkgray !important;
}

.Select--multi.is-disabled .Select-value-icon:hover,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:active {
  background-color: lightgray !important;
}

//.Select-menu-outer{
//  margin-top: 0 !important;
//}

.root {
  background-color: #ffff;
  // TODO get faintBlack from variables
}

.appBar {
  position: relative;
  padding-right: 0 !important;
  box-shadow: none;
}

.toolbar {
  padding-right: 8px;
}

.appBarButton {
  margin: 0 16px 0 8px;
}

.stepperWrapper {
  display: flex;
  background-color: #fff;
  height: 64px;
}

.stepper {
  width: 750px;
  margin: auto;
  vertical-align: middle;
}

.subheader {
  margin-top: 24px;
}

.content.content {
  display: flex;
  // background-color: theme.$lightGreyBackground;
  width: 100%;
  padding-top: 128px;
  min-height: 500px;
  overflow-y: scroll;
}

.step.step {
  padding-top: 48px;
  max-width: 500px;
  margin: auto;
  overflow: visible;
  min-height: 500px;
}

.formWrapper {
  width: 100%;
  padding-bottom: 64px;
}

.stepFooter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 48px
}

.divider {
  margin-top: 48px;
  margin-bottom: 16px;
  flex: 1 0 100%;
}

.divider2 {
  margin-top: 32px;
  margin-bottom: 0;
  flex: 3 0 100%;
}

.divider3 {
  margin-top: 24px;
  margin-bottom: 0;
  flex: 3 0 100%;
}

.radioTitle {
  width: calc(50% - 24px);
  margin-right: 24px;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
}

.datePickerControl {
  overflow: hidden;
  width: 200px;
  display: inline-block;
  vertical-align: middle;
  transition: width 150ms ease-in;
  margin-top: 0;
  margin-bottom: 0;
}

.datePickerVisible {
  width: 50%;
}

.stepButton.stepButton {
  margin-top: 16px;
  margin-left: 16px;
}

.padBottom {
  padding-bottom: 32px;
}

.flex {
  flex: 1;
}

.chip {
  display: inline-flex;
  padding: 0 0 0 0;
  margin: 5px 7px 0 0;
}

.selectedChip {
  background-color: theme.$primary;
  color: rgb(51, 59, 69);

  &:focus {
    background-color: theme.$primary;
    color: rgb(51, 59, 69);
  }
}

.halfWidth {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.item {
  margin-bottom: 16px;
  word-break: normal;
}

.employeeList {
  padding-left: 0;
  margin-top: 0;
}

.employeeListItem {
  list-style: none;
}

.menuItem {
  height: auto;
  display: flex;
}

.toolboxInput {
  height: auto;
}

.sectorInput {
  height: auto;
  display: initial;
}

.selectInput {
  & > div > div {
    min-height: 64px;
    padding: 8px 16px 0 16px;

    & > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.finalTitle {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  line-height: 24px;
}

.finalBox {
  background-color: #FFFFFF;
  padding: 16px 0 16px 16px;
  margin-bottom: 8px;
}

.icon {
  width: 18px;
  height: 18px;
  background-color: #ffdb4a;
}

.checkedIcon {
  &:before {
    display: block;
    width: 18px;
    height: 18px;
    background-image: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000000'/%3E%3C/svg%3E\")";
    content: "";
  }
}

.projectDropDown {
  //z-index: 9999;
}

.innerDialog {
  margin: auto;
  max-width: 500px;
  padding-top: 48px;
}