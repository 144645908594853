@use "../../../lib/themes/theme.scss" as theme;

.rootRoot {
  text-align: right;
  transition: margin-bottom 250ms ease-out;
  border-radius: 28px;
  margin-top: -65px;
}

.collapsedRootRoot {
  margin-bottom: -72px;
}

.root.root {
  position: relative;
  text-align: left;
  display: inline-block;
  transition: width 150ms ease-in 100ms, max-height 150ms ease-in 100ms, border-radius 150ms ease-in, transform 250ms ease-out;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
  padding: 0;
}

.collapsedRoot.collapsedRoot {
  width: 56px;
  max-height: 56px;
  border-radius: 28px;
  background-color: #00000000;
  margin-bottom: 44px;
}

.rootRoot .collapsedRoot {
  border-radius: 28px;
  top: 28px;
  right: 28px;
}

.topSection {
  padding: 24px;
  white-space: nowrap;
  background-color: #272D35;
  border-radius: 4px 4px 0 0;

  & > h2 {
    color: theme.$primary;
    margin: 0;
  }

  & > p {
    color: white;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;
  }
}

.collapsedTopSection {
  background-color: #00000000;
}
.topSectionTitleText.topSectionTitleText {
  opacity: 1;
  transition: opacity 80ms ease-out 200ms;
  user-select: none;
  color: theme.$textOnDarkBackground;
}
.topSectionText.topSectionText {
  opacity: 1;
  transition: opacity 80ms ease-out 200ms;
  user-select: none;
  color: theme.$lightGreyBackground;
}

.collapsedTopSectionText {
  transition: opacity 80ms ease-out;
  opacity: 0;
}

.bottomSection {
  padding: 24px;
}

.collapsedBottomSection {
  opacity: 1;
}

.button2 {
  margin: 0 0 -16px 0;
}

.items {
  margin: -16px -24px 16px -24px;
}

.guideShortCut {
  &:hover .shortcutIcon {
    color: theme.$primary;
  }

}

.shortcutIcon {
  color: theme.$secondary;

  &:hover {
    color: theme.$primary;
  }
}

.closeButton.closeButton {
  position: absolute;
  right: 28px;
  top: 28px;
  background-color: #272D35;

  &:hover {
    background-color: theme.$primary;
  }
}

.collapsedCloseButton.collapsedCloseButton {
  right: 0;
  top: 0;
  width: 56px;
  height: 56px;

  &:hover {
    background-color: theme.$primary;
  }
}

.collapseItem {
  position: absolute;
  transition: transform 500ms ease-out, opacity 50ms ease-in-out !important;
}

.rotate.rotate {
  opacity: 0;
  transform: rotate(180deg);
  transition: transform 500ms ease-in, opacity 50ms ease-in-out !important;
}

.buttonBody {
  color: black;
}
