.root.root {
  display: table-cell;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 14px;
  padding: 15px;
  border-top: 1px solid #DBDBDB;
  border-bottom: 1px  solid #DBDBDB;
  position: relative;
}
.head {
  background: red;
}
.noborder {
  border: none;
}

.nopadding.nopadding {
  padding-top: 0;
  padding-bottom: 0;
}
.body.body {
  position: relative;
  top: calc(50% - 2px);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.hover {
  opacity: 0;
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(-50%);
  left: 15px;
  z-index: 555;
  word-break: break-all;
  background-color: #f5f5f5;
  overflow: visible;
  transition: opacity 150ms ease-in;

&:hover {
  opacity: 1
}
}
.withHover {
  background-color: #eee;
}
.overflowVisible {
  overflow: visible;
}
//[theme.breakpoints.down('md')] {
//                                date {
//                                  width: 100px;
//                                  flex: 0 0 100px'
//                                }
//                              medium {
//                                width: 120px;
//                                flex: 0 0 120px'
//                              }
//                              }
