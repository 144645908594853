.root {
  position: relative;
}
.items {
  margin-top: 8px;
  display: inline-block;
  width: 100%;
}
.item {
  margin-top: 16px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 8px
  }
}
.itemActions {
  margin-top: 12px
}
.pasteMultiButton {
  display: inline-block;
  margin: 16px 0 0 0;
  vertical-align: middle;
}
.addButton {
  padding: 0 !important;
  margin: 16px 0 0 -16px;
  display: inline-block;
  vertical-align: middle;
}
