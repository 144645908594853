@use "../../lib/themes/theme.scss" as theme;

.root {
}

.title {
  color: theme.$highlightText;
  margin-top: 30px;
}

.titleHighlight {
  margin-top: 30px;
  color: theme.$highlightText,
}

.subtitle {
  font-size: 0.8em;
}

.highlight {
  color: theme.$highlightText;
  background: theme.$highlight;
  margin: 0 -16px;
  padding: 16px;
  margin-left: -16px;
}
