@use "../../lib/themes/theme" as theme;
.hideIcon.hideIcon {
  display: none;
}
.showIcon {

}
.appSideMenuOpen.appSideMenuOpen {
  margin-left: 260px;
}

.appSideMenuClosed.appSideMenuClosed {
  margin-left: 0;
}

.sideMenuOpen.sideMenuOpen {
  margin-left: 280px;
  min-height: 72px;
}

.sideMenuClosed.sideMenuClosed {
  margin-left: 0;
  min-height: 72px;
}

.app-bar {
  box-shadow: none !important;
}

.drawer-button {
  margin: 0 16px 0 8px;
}

.drawer-icon {
  height: auto;
  width: auto;
  color: theme.$secondary;
}

html {
  height: 100vh;
  background-color: #f5f5f5;
  overflow-x: hidden;
}

h1 {
  font-weight: normal;
}

h2 {
  font-weight: 400;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0;
}

.textarea {
  resize: none;
  width: 100%;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.04);
  height: 152px;
  padding: 20px;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
}

.react-viewer-mask,
.react-viewer-close,
.react-viewer-canvas,
.react-viewer-footer {
  position: fixed;
}

.border-text {
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 2px;
  height: 20px;
  width: 80px;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.87);

  &.ADDED,
  &.INACTIVE {
    background-color: #e73d2a;
    border-color: #e73d2a;
  }

  &.ACTIVE {
    background-color: #3fc81f;
    border-color: #3fc81f;
  }

  &.TRIAL {
    background-color: orange;
    border-color: orange;
  }

  &.EXTERN {
    color: #FFF;
    background-color: #C9C9C9;
    border-color: #C9C9C9;
  }
}

[role='radiogroup'] > label > :last-child {
  width: 100%;
}

.mui-fixed div[data-placement] {
  text-align: center;
  white-space: nowrap;
}

.filterbar {
  position: relative;
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
  margin: -20px -20px -20px 0;
  padding-top: 10px;
}

.crisp-client #crisp-chatbox {

  .crisp-4n0wrv.crisp-qm3u92 a.crisp-113f7m5 {

    .crisp-k30d4h.crisp-ukuhcj {
      background-color: #272D35 !important;

      .crisp-16lwunj {
        .crisp-n2psp0 {
          background-image: none !important;
        }
      }
    }
  }

  .crisp-k30d4h {
    position: relative !important;
  }

  .crisp-4n0wrv.crisp-qm3u92 {

    .crisp-6k5puw[data-last-operator-face=false] .crisp-4n0wrv .crisp-113f7m5 .crisp-k30d4h .crisp-16lwunj .crisp-n2psp0[data-is-ongoing=false] {
      background-image: none !important;
    }
  }

  .crisp-2csg7a {
    display: none !important;
  }

  .crisp-kquevr {
    bottom: 4px !important;

    .crisp-x94m06 {
      width: 56px !important;
      height: 56px !important;

      .crisp-16qgsyi .crisp-101bp3x[data-is-ongoing=false] {
        top: 16px !important;
        left: 11px !important;
      }
    }
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-left: -60px;
  font-size: 10px;
  line-height: 1.0;
  top: -21px;
  height: 10px;
  overflow: hidden;
  white-space: nowrap;

  opacity: 0;
  transition: opacity 1s;
}

.tooltip .tooltiptextvisible {
  visibility: visible;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-left: -60px;
  font-size: 10px;
  line-height: 1.0;
  top: -21px;
  height: 10px;
  overflow: hidden;
  white-space: nowrap;
  transition: opacity 1s;
  opacity: 0.6;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 0.6;
  position: absolute;
}

.Select-control {
  background: transparent !important;
  text-indent: 0 !important;
  cursor: text;

  .Select-placeholder {
    text-indent: 0;
    opacity: 0.55 !important;
    color: rgb(0, 0, 0) !important;
  }

  .Select-multi-value-wrapper {
    height: 28px !important;

    .Select-placeholder {
      padding: 0 !important;
    }

    & > div {
      height: 26px !important;
      padding: 0 5px !important;
    }
  }

  .Select-input {
    min-height: auto !important;
  }
}

.Select {
  width: 100%;
}

.geosuggest__suggests--hidden {
  box-shadow: none;
}

.deadline-datepicker {
  & > div > div > div {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

.material-light-caption {
  opacity: 0.6;
  font-size: 14px;
  text-align: left;
  line-height: 16px;
}

.medium-italic {
  font-style: italic;
  font-weight: 500;
}

.dropdown {
  background-color: transparent;
  right: 200px;
  top: 0;
  position: absolute;
  width: 150px;
}

.dropdown-settings {
  width: 250px;
}

@media (max-width: 768px) {
  .dropdown {
    right: 0;
  }
}

.display-linebreak {
  white-space: pre-line;
}

.MuiDialogActions-root {
  padding: 16px 24px !important; // Use !important if needed to ensure precedence
}

.MuiDialogContent-root {
  text-align: justify !important;
  text-justify: inter-word !important;
}