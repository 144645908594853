.guideButton.guideButton{
  padding: 8px 16px;
  margin: 0 8px 8px 8px;
  width: calc(33.33% - 16px);
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 1px solid #eee;
  text-align: left;
  border-radius: 2px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover{
    background-color: rgba(51, 59, 69, 0.12);
  }
}
.disabled {
  color: rgba(51, 59, 69, 0.12) !important
}
.title {
  width: calc(100% - 48px);
  display: inline-block;
  line-height: 20px;
  font-size: 16px;
  vertical-align: middle;
  white-space: nowrap;
}
.icon {
  text-align: right;
  width: 48px;
  display: inline-block;
  vertical-align: middle;
}
