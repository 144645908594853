.root {
}
.wrapper {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
}
.questionIcon {
  margin-left: 8px;
  margin-right: 8px;
}
.dragIcon {
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}
.deleteButton {
  margin-left: 8px;
  margin-right: 8px;
}
