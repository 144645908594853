.photo {
    margin: 0 24px;
}

.emptyState {
    margin: 20px 0 20px;
    padding: 20px 0;
    text-align: center;
    font-size: 12px;
    border-top: 1px solid #DBDBDB;
    border-bottom: 1px solid #DBDBDB;
}
.paper.paper {
    padding: 24px;
}
