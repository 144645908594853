@import '../../variables.scss';

.paper.paper{
  position: relative;
  width: 350px;
  padding: 24px;
}
.button {
  display: block;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 16px;
}
.message {
  margin: 16px 0;
}
.link {
}
.warningText {
  color: red;
}
