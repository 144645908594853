@use "../../../lib/themes/theme.scss" as theme;
@media (max-width: 768px) {

  #tbExternTitle {
    margin-left: -48px !important;
    text-align: center;
    width: 100%;
  }

  #externalPhoto {
    width: 100%;
  }

  #externalUl {
    padding-left: 0;
  }

  #tbExternPaperMain {
    margin-top: -30px;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;

  }

  #tbExternPaper {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0;
  }

  #tbExternPaperFeedback {
    margin-top: -30px;
    box-sizing: border-box;
    margin-bottom: 0;
  }

  #answerButton {
    width: 100%
  }

  #sendresultsButton {
    width: 100%
  }

  .appModule {
    padding: 0 0 !important;
  }

  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  #Root {
    min-width: 10px !important;
  }

  #stepper {
    width: 100%;
  }

  #checklist {
    width: 30% !important;
  }

  #expandbutton {
    margin-top: -10px !important;
  }
}

@media (max-width: 405px) {

  #expandbutton2 {
    margin-top: 25px !important;
  }
}

@media (max-width: 390px) {

  .weatherblock {
    width: 80px !important;
    height: 70px !important;
  }
}

@media (max-width: 298px) {

  .weatherblock {
    width: 70px !important;
  }
}

.weatherblock {
  margin-left: 15px !important;
  width: 100px;
  height: 80px; 
  border-radius: 6px;
  background-color: rgba(216,216,216,0.3);
  float: left;
  background-size: 64px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:first-of-type {
    margin-left: 0 !important;
  }
}

.weatherlist {
  display: inline-block;
  width:100%;
  
  &:not(:first-of-type) {
    margin-top: 15px !important;
  }
}
.root {
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}
.button {
  width: 100%;
  height: 64px;
}
.paper {
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 30px;
}
.item.item {
  margin-top: 24px;
  margin-bottom: 16px;
  z-index: 0;
}

.stepperWrapper {
  background-color: #ffff;
}
.stepper {
  margin: auto;
}
.sectorInput {
  height: auto;
  display: initial;
}
.divider3 {
  margin-top: 18px;
  margin-bottom: 0;
  flex: 3 0 100%;
}
.stepper > div {
  padding: 26px;
}
.backButton{
  color: theme.$highlightText
}