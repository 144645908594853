.root.root {
  width: 100%;
  //max-width: 1260px;
  display: table-row;
  align-items: stretch;
  height: auto;
}
//[theme.breakpoints.down('lg')]: {
//                                root: {
//                                  maxWidth: 'initial'
//                                }
//                              },
.hover {
  cursor: pointer;
  transition: background-color 350ms;
  &:hover {
    background-color: #eee;
  }
}
.notify {
  background-color: #E5EEF9;
}
