@use "../../lib/themes/theme.scss" as theme;
@media (min-width: 1186px) {

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 784px !important;
    margin: auto;
  }
}

@media (max-width: 1185px) {

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 784px !important;
    margin: auto;
  }
}

@media (max-width: 810px) {

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 342px !important;
    margin: auto;
  }

  .card {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 450px) {

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw !important;
    margin: auto;
  }

  .card {
    width: calc(100vw - 50px) !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
.vvlogo {
  height: 64px;
  position: fixed;
  right: 16px;
  padding: 8px;
  box-sizing: border-box;
  top: 0;
  z-index: 1200;
}
.card {
  display: inline-block;
  position: relative;
  height: 348px;
  width: 342px;
  border-radius: 2px;
  background-color: #FFFFFF;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 60px;
}
.cardbg {
  height: 152px;
  width: 342px;
  border-radius: 2px 0 0 2px;
  background-color: #F8F8F8;
}
.cardbutton {
  height: 52px;
  width: 100%;
  border-radius: 2px;
  background-color: theme.$primary;
  position: absolute;
  bottom: 0;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
  color: theme.$highlightText;
}
.linkStyle {
  text-decoration: none;
  color: inherit;
}
.button {
  position: fixed;
  top: 40px;
  right: 36px;
  z-index: 1102;
}
.fabButton {
  &:hover {
    color: theme.$primary;
  }
}
.dropdown{
  right: 0;
}
.tab.tab{
  color: theme.$highlightText;
}
.opdrachtType {
  width: 50px;
  height: 50px;
  background-color: white;
  float: right;
  text-align: center;
  line-height: 50px;
  color: #727272;
  font-weight: bold;
}
.author {
  padding-left: 14px;
  padding-top: 0;
  line-height: 50px;
  color: #727272;
  background-color: #DDDDDDA0;
  font-weight: 300;
}
.boldText {
  font-weight: bold;
}
.dateTimeText {
  position: absolute;
  top: 210px;
  bottom: 115px;
  text-align: center;
  width: 100%;
  color: theme.$text;
  font-weight: 300;
  font-size: 13px;
}
.descriptionText {
  position: absolute;
  top: 232px;
  text-align: center;
  width: 90%;
  margin-left: 15px;
  bottom: 65px;
  font-weight: 300;
}
.arrowStyle {
  position: absolute;
  right: 13px;
  top: 13px;
  width: 20px;
}

.noDataContainer {
  margin-top: 30vh;
}
.noDataOverviewIcon {
  margin: auto;
  width: 100%;
  height: 64px;
  opacity: 0.4;
}
.noDataMessage {
  text-align: center;
  opacity: 0.4;
}