.root.root {
  position: fixed;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  // Todo get borderTop from theme theme.palette.grey[300]
  border-top: 1px solid #eeee;
  // background: linear-gradient(bottom, white 45%, rgba(255,255,255,0.85) 100%);
  background-color: #ffffff;
  z-index: 1001;
  padding: 0 32px 0 25px;
}
.row.row {
  width: 100%;
  //max-width: 1800px;
  //height: 48px;
  display: block;
  margin: auto;
}
.cell.cell {
  border-top: none;
  display: block;
  float: right;
  width: 100%;
  padding: 0 15px;
//[theme.breakpoints.down('md')] {
//  paddingRight: '300px!important;
//}
  &:hover {
    background-color: white !important;
  }
}
.children.children {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
