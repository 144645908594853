.root {
  padding-top: 56px;
  text-align: center;
  font-size: 16px;
}
.text {
  opacity: 0.5
}
.emptyButton {
  width: 120px;
}
