.root {
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.cardHeader {
  cursor: pointer;
  &:last-child {
    padding-bottom: 16px
  }
  overflow: hidden;
  &:nth-child(n+1) {
    width: calc(100vw - 750px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.cardContent {
  padding-top: 0;
  padding-left: 64px;
  padding-right: 64px;
}
.cardActions {
  position: absolute;
  top: 6px;
  right: 6px;
}
.expand {
  transform: rotate(0deg);
  transition: transform 1s;
}
.expandOpen {
  transform: rotate(180deg);
}
