@media (max-width: 650px) {

  #toolboxBlocks {
    width: 100% !important;
  }

  #toolboxText {
    width: calc(100vw - 180px) !important;
  }
}
.button {
  position: fixed;
  top: 40px;
  right: 36px;
  z-index: 1211;
}