@use "../../lib/themes/theme.scss" as theme;
.confirmButtonContainer {
    width: 100%;
    text-align: right;
    margin-top: 32px;
    margin-bottom: 16px; 
}

.confirmButton.confirmButton {
    margin-right: 16px;
    color: theme.$secondary;
    &:hover {
        background-color: #f5f5f5; // Light gray on hover
    }

}