.appBar {
  position: relative;
  padding-right: 8px;
  box-shadow: none;
}

.appBarButton {
  margin: 0 16px 0 8px;
}

.content {
  padding-top: 18px;
}

.flex {
  flex: 1;
}

.abonnementInfoContent {
  width: 650px;
  margin-top: 64px;
  margin-bottom: 24px;
}

.abonnementInfoCard {
  width: 348px;
  height: 528px;
  float: right;
  margin-top: 30px;
}

.abonnementContent {
  width: 1100px;
  margin: 64px auto 24px;
}

.upgradeButton.upgradeButton {
  width: 139px;
  margin-top: 42px;
  margin-left: 10px;
}

.pElement {
  display: block;
  margin: 1em 0;
}

.customPadding.customPadding {
  padding: 0 24px !important;
}