@use "../../lib/themes/theme.scss" as theme;
.appBar.appBar{
  position: relative;
  padding-right: 16px;
  background: theme.$secondary;
  // color: #fff;
  z-index: 0;
}
.appBarButton.appBarButton{
  margin: 0 16px 0 8px;
  color: #fff;
}
.tab.Mui-selected {
  color: #3fc81f !important;
}
.content.content{
  padding-top: 24px;
  padding-right: 19px;
}
.toolbarLogo {
  margin-left: 24px;
  width: 48px;
  height: 48px;
}
.toolboxTitle.toolboxTitle {
  margin-left: 24px;
}
.flex{
  flex: 1;
}
.cancelButton.cancelButton {
  margin: 16px;
}
.sectorsSelect{
  min-width: 125px;
}
.toolboxInfoContent{
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  margin-top: 16px;
  margin-bottom: 24px;
}
.toolboxInfoIcon{
  flex: 0 0 auto;
  margin: 16px 0 16px 16px;
  font-size: 11px;
  text-align: center;
}
.toolboxInfoForm{
  flex: 1 0 auto;
  width: 700px;
  margin-left: 0;
  margin-right: 16px;
}
.inputFields.inputFields{
  margin-top: 8px;
  margin-bottom: 8px;
}
.editors{
  background: theme.$faintBlack;
}
.tooltiptext {
  background-color: #f4f4f4;
  color: #000;
  text-align: center;
  //borderRadius: 2px;
  padding: 5px 10px;
  top: -25px;
  position: sticky;
  z-index: 1;
  bottom: 100%;
  //marginLeft: -60px;
  font-size: 10px;
  line-height: 1.0;
  //top: -21px;
  height: 10px;
  overflow: hidden;
  white-space: nowrap;
  visibility: visible;
  opacity: 0.9;
}
.editors.editors{
  padding: 0
}
.tabsAppBar.tabsAppBar{
  background-color: theme.$primary;
}
.appBar.appBar h2, .appBar.appBar svg{
  color: theme.$highlight;
}
.toolboxEditClass.toolboxEditClass{
  padding: 0;
}

.toolboxEdit {
  .preview {
    width: 100% !important;
    padding: 0;
    margin: 0;
    border-right: 0 solid #dbdbdb;
  }

  .previewView {
    width: 100% !important;
  }
}