.root {
  width: calc(25% - 16px);
  display: inline-block;
  margin: 8px;
  vertical-align: top;
  min-width: 200px;
}
.paper.paper {
  padding: 24px;
  &:hover {
    background-color: #f5f5f5; // Light gray on hover
  }
}
.title {
  font-size: 16px;
  margin-bottom: 8px;
  min-height: 42px;
}
.value {
  font-size: 24px;
  display: inline-block;
}
.unit {
  font-size: 14px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: bottom;
  opacity: 0.45;
}
.image {
  display: inline-block;
}
