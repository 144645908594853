.root.root {
  display: inline-block;
  width: calc(50% - 16px);
  vertical-align: top;
  margin: 8px;
  min-width: 550px;
}
.paper.paper {
  padding: 24px;
}
.title.title {
  margin: 0 -24px;
  padding: 0 24px;
  border-bottom: 1px solid #eee;
  position: relative;
}
.titleText.titleText {
  font-size: 16px;
  margin-bottom: 24px;
  display: inline-block;
  top: 0;
  width: 70%;
  margin-right: 0;
  padding-right: 0;
}
.titleButton.titleButton {
  float:right;
  color: #272D35;
  &:hover {
    background-color: #f5f5f5; // Light gray on hover
  }

}
.content.content {
  height: 240px;
  margin: 0 -24px;
}
