.root {
  //width: 800px;
}

.dialogContent {
  //width: 800px;
}

.errorMessage {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
}
