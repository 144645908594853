.paperCard.paperCard {
  width: calc((100% / 3) - (32px / 3));
  display: inline-block;
  vertical-align: top;
  min-width: 200px;
}

.paperCardLeft.paperCardLeft {
  margin-right: 8px;
}

.paperCardRight.paperCardRight {
  margin-left: 8px;
}

.paper.paper {
  padding: 24px;
}

.questionsCard.questionsCard {
  display: inline-block;
  width: calc(100%);
  vertical-align: top;
  margin-top: 24px;
  min-width: 550px;
}

.title {
  font-size: 16px;
  margin-bottom: 8px;
  min-height: 42px;
}

.value {
  font-size: 24px;
  display: inline-block;
}

.unit {
  font-size: 14px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: bottom;
  opacity: 0.45;
}