@use "../../../lib/themes/theme.scss" as theme;
#tbExternPaper {
  position: relative;
  max-width: 610px;
  margin: auto;
}

@media (max-width: 768px) {

  #tbExternTitle {
    margin-left: -48px !important;
    text-align: center;
    width: 100%;
  }

  #externalPhoto {
    width: 100%;
  }

  #externalUl {
    padding-left: 0;
  }

  #tbExternPaperMain {
    margin-top: -30px;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;

  }

  #tbExternPaper {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0;
  }

  #tbExternPaperFeedback {
    margin-top: -30px;
    box-sizing: border-box;
    margin-bottom: 0;
  }

  #answerButton {
    width: 100%
  }

  #sendresultsButton {
    width: 100%
  }

  .appModule {
    padding: 0 0 !important;
  }

  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  #Root {
    min-width: 10px !important;
  }

  .checkIcon {
    right: 50px !important;
  }
}
.root {
  min-width: 128px;
  max-width: 768px;
  margin: 32px auto;
  padding: 32px;
}
.toolboxMeetingBox {
  margin-top: 40px;
  padding: 16px;
  float: left;
  width: 30%;
  height: 100%;
  background-color: #FFF;
}

.toolboxMeetingBoxH3 {
  font-size: 20px;
  width: 100%;
  padding: 16px;
  padding-bottom: 10px;
}
.toolboxMeetingLeft {
  padding: 16px;
  float: left;
  word-break: break-all;
  width: 230px;
}
.toolboxMeetingRight {
  float: left;
  padding: 16px;
}
.unselectedAnswer {
  background-color: #F5F5F5;
  padding: 16px;
  margin-top: 16px;
  border: 1px solid #696969;
  border-radius: 5px;
  color: #292929;
  cursor: pointer;
  user-select: none;
}
.backButton{
  color: theme.$highlightText
}