@use "../../../lib/themes/theme.scss" as theme;
@media (max-width: 768px) {

  #tbExternTitle {
    margin-left: -48px !important;
    text-align: center;
    width: 100%;
  }

  #externalPhoto {
    width: 100%;
  }

  #externalUl {
    padding-left: 0;
  }

  #tbExternPaperMain {
    margin-top: -30px;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;

  }

  #tbExternPaper {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0;
  }

  #tbExternPaperFeedback {
    margin-top: -30px;
    box-sizing: border-box;
    margin-bottom: 0;
  }

  #answerButton {
    width: 100%
  }

  #sendresultsButton {
    width: 100%
  }

  .appModule {
    padding: 0 0 !important;
  }

  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  #Root {
    min-width: 10px !important;
  }
}
.root {
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}
.paper {
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 30px;
}
.unselectedAnswer {
  background-color: #F5F5F5;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 12px;
  border: 1px solid #696969;
  border-radius: 5px;
  color: #292929;
  cursor: pointer;
  user-select: none;
}
.outcomeText {
  padding-bottom: 16px;
  margin-top: -32px;
}
.toolboxMeetingBox {
  display: inline-block;
  width: 100%;
  background-color: #272D35;
  margin-top: 12px
}
.toolboxMeetingBoxH3 {
  color: #FFDC4A;
  font-size: 20px;
  padding-top: 12px;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
}
.toolboxMeetingLeft {
  width: 96px;
  color: #FFFFFF;
  opacity: 0.6;
  float: left;
  padding-left: 12px;
  padding-bottom: 12px;
}
.toolboxMeetingRight {
  color: #FFFFFF;
  float: left;
  padding-bottom: 20px;
}
.backButton{
  color: theme.$highlightText
}