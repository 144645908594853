@use "../../lib/themes/theme.scss" as theme;
.appBar.appBar {
  position: relative;
  padding-right: 16px;
  background: #333b45;
  color: #fff;
}
.appBarButton.appBarButton {
  margin: 0 16px 0 8px;
  color: #fff;
}
.content.content {
  padding-top: 24px;
  padding-right: 19px;
}
.flex.flex {
  flex: 1;
  color: theme.$primary;
  margin: 16px;

}
.cancelButton.cancelButton {
  margin: 16px;
}
.toolbarLogo {
  margin-left: 24px;
  width: 48px;
  height: 48px;
}
.title.title {
  flex: 1;
  color: theme.$primary;
  margin-left: 24px;
}
.sectorsSelect {
  min-width: 125px;
}
.inspectionInfoContent {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  margin-top: 16px;
  margin-bottom: 32px;
}
.inspectionInfoIcon {
  flex: 0 0 auto;
  margin: 16px 0 16px 16px;
  font-size: 11px;
  text-align: center;
}
.inspectionInfoForm {
  flex: 1 0 auto;
  width: 700px;
  margin-left: 0;
  margin-right: 16px;
}
.editors {
  background: theme.$faintBlack;
}
