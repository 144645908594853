.searchbar {
  height: 30px;
  width: 215px;
  position: absolute;
  left: 40px;
  top: 4px;

  background: transparent;
  border: none;
  font-size: 15px;
  outline: none;
}

.searchbox {
  height: 40px;
  width: 0;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 156px;
  top: 50%;
  border-radius: 2px;
  transform: translateY(-50%);
  transition: width 300ms;
  overflow: hidden;
  z-index: 100;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0, 0, 0, 0.4);
    opacity: 1; /* Firefox */
  }
}

.searchboxOpen {
  width: 300px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.4);
}

:-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.4);
}

.clearButton {
  position: absolute !important;
  right: 0;
  top: 0;
  display: none;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 8px;
  opacity: 0.4;
  transition: opacity 300ms !important;
  z-index: 80;
}

.searchIconWithText {
  opacity: 1;
}

.searchButton {
  position: absolute !important;
  right: 156px;
  top: 15px;
}

.searchButtonOpen {
  display: none !important;
}

.root {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #DBDBDB;
}

.wrapper {
  padding: 0 50px 0 50px;
  margin: auto;
  max-width: 1800px;
  //TODO what should max-width be?
}

.content {
  position: relative;
  padding: 24px 0 24px 15px;
  font-size: 16px;
  box-sizing: border-box;
  min-height: 68px;
}

.contentwide {
  margin: auto;
  position: relative;
  padding: 24px 0 24px 0;
  font-size: 16px;
  box-sizing: border-box;
  min-height: 68px;
}

.button {
  float: right;
  position: relative;
  bottom: 12px;
}

.actionButton.actionButton {
  height: 40px;
  width: 140px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.onDropdownButton1.onDropdownButton1 {
  height: 40px;
  width: 140px;
  position: absolute;
  right: 0;
  top: calc(50% + 56px);
  transform: translateY(-50%);
  z-index: 1000;
}

.onDropdownButton2.onDropdownButton2 {
  height: 40px;
  width: 140px;
  position: absolute;
  right: 0;
  top: calc(50% + 56px + 56px);
  transform: translateY(-50%);
  z-index: 1000;
}
