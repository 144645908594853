.root {
}

.dialogContent {
  width: 400px;
}

.errorMessage {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
}
