// @import '../../variables.scss';

.card-content {
    padding: 32px;
    position: relative;
}

.statistics-topbar {
    position: relative;
}

.statistics-overflow-button {
    position: absolute !important;
    bottom: 8px;
    right: 40px;
}

.statistics-overflow-button > div > span {
    color: #555 !important;
}

.statistics-filter-bar-wrapper {
    width: calc(100% - 64px);
    min-height: 80px;
}

.statistics-graphs-control {
    cursor: pointer;
    position: relative;
    top: -56px;
}

ul.tabs li.tab a {
    color: rgb(158, 158, 158);
    cursor: pointer;
    user-select: none;
}

ul.tabs li.tab a:hover {
    color: rgb(158, 158, 158);
    text-decoration: none !important;
}

ul.tabs li.tab a.active {
    color: #2369bc;
}

ul.tabs .indicator {
    background-color: #2369bc;
}

.statSummary {
    text-align: center;
}

.statistics-summary-item {
    display: inline-block;
}

.statistics-summary-item:not(:last-child) {
    margin-right: 16px;
}

.statistics-summary-item-key {
    color: rgb(158, 158, 158);
    display: inline-block;
}

.statistics-summary-item-value {
    color: #272d35;
    display: inline-block;
    font-weight: 500;
}

.statistics-graphs-active-graph {
    opacity: 1;
    -webkit-transition: opacity 200ms;
    -moz-transition: opacity 200ms;
    -o-transition: opacity 200ms;
    transition: opacity 200ms;
    margin-top: 40px;
}

.statistics-list {
    margin: -32px;
    opacity: 1;
    -webkit-transition: opacity 200ms;
    -moz-transition: opacity 200ms;
    -o-transition: opacity 200ms;
    transition: opacity 200ms;
}

.statistics-list tr > :first-child {
    position: relative;
    left: 32px;
    padding-right: 32px;
}

.statistics-list td {
    background-color: transparent !important;
}

.pagination {
    padding-right: 36px;
}

.loading-hidden {
    opacity: 0;
}

.statistics-loader {
    opacity: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-transition: opacity 200ms, height 200ms;
    -moz-transition: opacity 200ms, height 200ms;
    -o-transition: opacity 200ms, height 200ms;
    transition: opacity 200ms, height 200ms;
    height: 0 !important;
    position: absolute !important;
    left: 0 !important;
    top: -2px !important;
}

.statistics-loader.visible {
    opacity: 1 !important;
    height: 4px !important;
}

g.xAxis .recharts-cartesian-axis-tick text tspan {
    font-size: 12px;
}

.chart-tooltip {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 16px;
    width: 220px;
}

.chart-tooltip-title {
    color: rgba(220, 220, 220, 0.7);
    font-weight: 500;
    margin-bottom: 8px;
}

.chart-tooltip-days {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    display: none; // Temporarily disabled
}

.chart-tooltip-row {
    padding: 4px 0;
}

.chart-tooltip-row * {
    display: inline-block;
    vertical-align: middle;
}

.chart-tooltip-color {
    width: 12px;
    height: 12px;
    margin-right: 16px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.chart-tooltip-row :nth-child(n + 2) {
    width: calc(70% - 28px);
}

.chart-tooltip-row :last-child {
    width: 30%;
}

.chart-tooltip-key {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
    font-weight: 400;
}

.chart-tooltip-value {
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
}

.chart-label-block {
    display: flex;
    margin-bottom: 64px;
    position: relative !important;
}

.recharts-legend-item-text {
    font-size: 14px !important;
}
