.button.button {
  position: fixed;
  top: 84px;
  right: 36px;
  z-index: 1102;
}
.appModule {
  margin-top: 0;
}
.taskNotFinished {
  background-color: #CFCFCF;
  text-align: center;
  height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
}

.taskFinished {
  background-color: #47CF21;
  text-align: center;
  height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
}
.filterBar {
  margin-top: auto;
  margin-bottom: auto;
}
.statusDropdown {
  margin-left: 20px;
}
