
.incidentPreview {
  width: 60%;
  float: left;
  padding-right: 44px;
  margin-bottom: 32px;
  border-right: 1px solid #DBDBDB;
}

.info {
  width: 30%;
  float: right;
  padding-left: 0;
  margin-top: 64px;
  position: relative;
}
.previewView {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}

.incidentHeader {
  font-size: 20px;
  margin-left: 24px;
  float: left;
}
.divider {
  width: 100%;
}
.itemRow {
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 8px;
}
.rowTitle {
  margin-bottom: -12px;
}
.saveButton.saveButton {
  margin-left: 16px;
  float: right;
}

.button.button {
  position: fixed;
  top: 84px;
  right: 36px;
  z-index: 1102;
}
.item.item {
  display: inline-block;
  width: calc(50% - 24px);
  margin: 24px 0 24px 0;
}
.item2.item2 {
  display: inline-block;
  width: calc(50% - 24px);
  margin: 24px 0 0 48px;
}
.item3.item3 {
  display: inline-block;
  width: calc(50% - 24px);
  margin: 32px 0 0 48px;
}
.radioTitle {
  margin-left: 24px;
  width: calc(50% - 24px);
  white-space: nowrap;
  vertical-align: middle;
}
.datePickerControl {
  overflow: hidden;
  width: 200px;
  display: inline-block;
  vertical-align: middle;
  transition: width 150ms ease-in;
  margin-top: 24px;
  margin-bottom: 0;
}
.datePickerVisible {
  width: 50%;
}
.fixedContainer {

}
