.root {
  position: relative;
}
.items {
  margin-top: 8px;
  display: inline-block;
}
.item {
  margin-top: 16px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 8px;
  }
}
.addButton {
  padding: 0 !important;
  margin: 16px 0 0 -16px;
  display: inline-block;
}

.paddingTwelve {
  padding-top: 12px;
}