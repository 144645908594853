@import '../../variables.scss';

.AuthPage {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-image: url("/assets/images/background-dude.jpg");
    background-size: cover;
    background-position: 50% 35%;

    &__logo {
        position: absolute;
        display: inline-block;
        height: auto;
        width: 200px;
        z-index: 2;
        top: 40px;
        left: 40px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.45);
    }
}
.filter {
    position: absolute;
    top: 50px;
    right: 50px;
    background-color: rgba(0,0,0,0);
}
.labelColor {
    color: #fff !important;
}
.selectRootColor.selectRootColor {
    box-shadow: none;
    color: #fff;
    &:before {
        background-color: white;
        height: 1px;
    }
}

