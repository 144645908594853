@use "../../../lib/themes/theme.scss" as theme;

.button.button {
  position: fixed;
  top: 84px;
  right: 36px;
  z-index: 1102;
}
.flex {
  flex: 1px;
}

.root {
}

.appBar {
  position: relative;
  padding-right: 0 !important;
  box-shadow: none;
}
.toolbar {
  padding-right: 8px;
}
.appBarButton {
  color: theme.$highlightText;
}
.stepperWrapper {
  display: flex;
  background-color: #fff;
  height: 64px;
}
.stepper {
  width: 750px;
  margin: auto;
  vertical-align: middle;
}
.subheader {
  margin-top: 24px;
}
.content.content {
  display: flex;
  padding: 0;

  width: 100%;
  padding-top: 128px;
  min-height: 500px;
  overflow-y: scroll;
}
.formWrapper {
  margin-left: 164px;
  margin-right: 164px;
  width: 100%;
  padding-bottom: 64px;
}
.inputElement.inputElement {
  margin: 24px;
}
.createdBy {
  color: #00000050;
}
.createdDate.createdDate {
  color: #00000050;
  margin-bottom: 24px
}
.message.message {
  margin-bottom: 24px;
  margin-top: 24px;
}
.photoBlock {
  display: inline-block;
  margin-bottom: 48px;
}
.photoItem {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
}
.divider.divider {
  margin-top: 48px;
  margin-bottom: 48px;
}
.wrapContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.responseCardContent {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.photoThumb {
  &:hover {
    cursor: nesw-resize;
  }
  max-width: 128px;
  max-height: 128px;
  margin-top: 24px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
}
.photoLarge {
  &:hover {
    cursor: nesw-resize;
  }
  max-width: 512px;
  max-height: 512px;
  margin-top: 24px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
}

