.myContainer {
    padding-left: 16px;
    padding-right: 16px;
}
.editButton {
    float: right;
    position: relative;
    bottom: 12px;
}
.item {
    display: inline-block;
    width: 100%;
    margin: 16px 0;
}

.sectionTitle {
    display: block;
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 8px;
}

.tableCardTitle {
    margin-left: 8px;
}

.topPadding {
    padding-top: 0.5px;
}