@use "../../lib/themes/theme.scss" as theme;

.formControl {
  margin: 0;
  min-width: 170px;
}
.selectRootColor {
  width: 100%;
}
.menupopup.menupopup {
  padding: 0;
}
.LanguageDropdown.LanguageDropdown{
  color: theme.$highlightText;
}
.LanguageDropdown.LanguageDropdown::before{
  border-bottom:1px solid theme.$highlightText;
}
.color.color{
  color: theme.$highlightText;
}
  
.LanguageDropdown.LanguageDropdown svg{
    color: theme.$highlightText;
  }