.topBlock {
    margin-top: 20px;
    margin-left: 8px;
    display: inline-flex;
    width: 100%;
}
.menuIcon {
    width: 1em;
    height: 1em;
}
.closeButton {
    padding: 0;
    width: 1em;
    height: 1em;
}
.sideMenuLogo{
    height: 64px;
    margin-left: 16px;
    padding: 0;
    img{
        height: 100%;
    }
}
.icon{
    color: #000
}
.bottomList.bottomList{
    margin-top: auto;
}
